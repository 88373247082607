/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import url from "../../config/axios";
import Popup from "reactjs-popup";
export default function AddPoints() {
  const admin = JSON.parse(localStorage.getItem("Admin"));

  const history = useHistory();
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    ref.current.close();
  };
  const [loyalty, setLoyalty] = useState();
  const [points, setPoints] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [day, setDay] = useState("Select Day");
  // setting error messages useState
  const [dayError, setDayError] = useState();
  const [pointsError, setPointError] = useState();
  const [pointsToPrice, setPointsToPrice] = useState(0);
  const [PointsToPointsEarnedRatio, setPointsToPointsEarnedRatio] = useState(0);
  const [PointsForRegistration, setPointsForRegistration] = useState(0);
  const [PointsOnFirstOrder, setPointsOnFirstOrder] = useState(0);
  const [message, setMessage] = useState({
    display: false,
    status: "",
    text: "",
  });

  // setting error message for points
  const time = [
    { time: "12 AM", value: 0 },
    { time: "1 AM", value: 1 },
    { time: "2 AM", value: 2 },
    { time: "3 AM", value: 3 },
    { time: "4 AM", value: 4 },
    { time: "5 AM", value: 5 },
    { time: "6 AM", value: 6 },
    { time: "7 AM", value: 7 },
    { time: "8 AM", value: 8 },
    { time: "9 AM", value: 9 },
    { time: "10 AM", value: 10 },
    { time: "11 AM", value: 11 },
    { time: "12 PM", value: 12 },
    { time: "1 PM", value: 13 },
    { time: "2 PM", value: 14 },
    { time: "3 PM", value: 15 },
    { time: "4 PM", value: 16 },
    { time: "5 PM", value: 17 },
    { time: "6 PM", value: 18 },
    { time: "7 PM", value: 19 },
    { time: "8 PM", value: 20 },
    { time: "9 PM", value: 21 },
    { time: "10 PM", value: 22 },
    { time: "11 PM", value: 23 },
  ];

  const GetLoyaltyStandards = async () => {
    await url
      .get(`/loyalty?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          await setLoyalty(res.data.data);
          setPointsToPrice(res.data.data.pointsToPrice);
          setPointsOnFirstOrder(res.data.data.PointsOnFirstOrder);
          setPointsForRegistration(res.data.data.PointsForRegistration);
          setPointsToPointsEarnedRatio(res.data.data.PointsToPointsEarnedRatio);
        } else {
          // alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };
  // updating  specialized time
  const check = async () => {
    if (day === "Select Day" && points === 0) {
      setDayError("Day is required");
      setPointError("Points are required");
      closeTooltip();
    } else if (day === "" || day === "Select Day") {
      setDayError("Day is required");
      closeTooltip();
    } else if (points === 0) {
      setPointError("Points are required");
      closeTooltip();
    } else {
      openTooltip();
    }
  };

  const updateSpecializeTime = async () => {
    console.log(points, "Current value");

    console.log(loyalty[day], "ACTUAL");
    let value = loyalty[day];
    for (var x = from; x - 1 < to; x++) {
      value[x] = points;
    }
    setLoyalty((prev) => {
      return {
        ...prev,
        day: value,
      };
    });

    await url
      .put(
        `/loyalty?loyaltyID=${loyalty.loyaltyID}&consumerId=${admin[0].consumerId}`,
        loyalty
      )
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          setMessage({
            status: "close",
            display: true,
          });
          openTooltip();
          // setTimeout(() => {
          //   history.push("/loyalty-points");
          // }, 1000);
        } else {
          // alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const updateLoyalty = async () => {
    let body = {
      pointsToPrice: pointsToPrice,
      PointsOnFirstOrder: PointsOnFirstOrder,
      PointsToPointsEarnedRatio: PointsToPointsEarnedRatio,
      PointsForRegistration: PointsForRegistration,
    };
    await url
      .put(
        `/loyalty?loyaltyID=${loyalty.loyaltyID}&consumerId=${admin[0].consumerId}`,
        body
      )
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          setMessage({
            status: "close",
            display: true,
          });
          openTooltip();
          // setTimeout(() => {
          // }, 1000);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    GetLoyaltyStandards();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        {" "}
        <div className="card card-pro">
          <div>
            {" "}
            <div className="row" style={{ padding: "0px 30px 30px " }}>
              <div className="col-md-6"></div>
              <div className="col-md-6" style={{ textAlign: "right" }}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    history.push("/loyalty-points");
                  }}
                  style={{ marginTop: "0" }}
                >
                  <i className="fas fa-arrow-left"></i>
                  Normal Schedule
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h4>Add Points</h4> <br />
                <div className="group-comb">
                  <div className="form-group">
                    <label>Points to price*</label>
                    <input
                      type="number"
                      // value={dealName}
                      onInput={(e) => {
                        if (e.target.value > 100) {
                          e.target.value = 100;
                        }
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                      }}
                      onChange={(e) => {
                        setPointsToPrice(e.target.value);
                      }}
                      className="form-control"
                      // id="exampleFormControlInput1"
                      placeholder={loyalty?.pointsToPrice}
                    />
                  </div>
                </div>
                <div className="group-comb">
                  <div className="form-group">
                    <label>Price to points earned ratio*</label>
                    <input
                      type="number"
                      onInput={(e) => {
                        if (e.target.value > 100) {
                          e.target.value = 100;
                        }
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                      }}
                      // value={dealName}
                      onChange={(e) =>
                        setPointsToPointsEarnedRatio(e.target.value)
                      }
                      className="form-control"
                      // id="exampleFormControlInput1"
                      placeholder={loyalty?.PointsToPointsEarnedRatio}
                    />
                  </div>
                </div>
                <div className="group-comb">
                  <div className="form-group">
                    <label>Points earned for registration*</label>
                    <input
                      type="number"
                      // value={dealName}
                      onInput={(e) => {
                        if (e.target.value > 100) {
                          e.target.value = 100;
                        }
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                      }}
                      onChange={(e) => setPointsForRegistration(e.target.value)}
                      className="form-control"
                      // id="exampleFormControlInput1"
                      placeholder={loyalty?.PointsForRegistration}
                    />
                  </div>
                </div>
                <div className="group-comb">
                  <div className="form-group">
                    <label>Points earned on first order*</label>
                    <input
                      type="number"
                      onInput={(e) => {
                        if (e.target.value > 100) {
                          e.target.value = 100;
                        }
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                      }}
                      // value={dealName}
                      onChange={(e) => setPointsOnFirstOrder(e.target.value)}
                      className="form-control"
                      // id="exampleFormControlInput1"
                      placeholder={loyalty?.PointsOnFirstOrder}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setMessage({
                      status: "update",
                      display: true,
                    });

                    openTooltip();
                  }}
                  className="btn  btn-cannaby"
                >
                  Save
                </button>
                <button
                  style={{ marginLeft: "20px", marginTop: "30px" }}
                  type="button"
                  onClick={() => history.push("/loyalty-points")}
                  className="btn btn-rnav"
                >
                  Cancel
                </button>
              </div>
              <div className="col-md-6">
                <h4>Specialized time</h4>
                <br />
                <label>Day</label>
                <select
                  className="form-control"
                  style={{ width: "100%" }}
                  id="exampleFormControlSelect1"
                  onChange={(e) => {
                    setDay(e.target.value);
                    setDayError("");
                  }}

                  // value={orderStatus}
                  // onChange={(e) => setOrderStatus(e.target.value)}
                  // disabled={order.status === "Dispatched" ? true : false}
                >
                  {/* <option value={orderStatus}>{orderStatus}</option>  */}
                  <option value="Select Day">Select Day</option>
                  <option value="monday">Monday</option>
                  <option value="tuesday">Tuesday</option>
                  <option value="wednesday">Wednesday</option>
                  <option value="thursday">Thursday</option>
                  <option value="friday">Friday</option>
                  <option value="saturday">Saturday</option>
                  <option value="sunday">Sunday</option>
                </select>
                <p style={{ color: "red", fontSize: "11.5px" }}>{dayError}</p>
                <div style={{ marginTop: "20px" }} className="select-time row">
                  <div></div>
                  <div className="col-md-6">
                    <label>From</label>
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(e) => setFrom(e.target.value)}
                      // value={orderStatus}
                      // onChange={(e) => setOrderStatus(e.target.value)}
                      // disabled={order.status === "Dispatched" ? true : false}
                    >
                      {time.map((item) => {
                        return <option value={item.value}>{item.time}</option>;
                      })}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label>To</label>
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      onChange={(e) => setTo(e.target.value)}
                      // value={orderStatus}
                      // onChange={(e) => setOrderStatus(e.target.value)}
                      // disabled={order.status === "Dispatched" ? true : false}
                    >
                      {time.map((item) => {
                        return <option value={item.value}>{item.time}</option>;
                      })}
                    </select>
                  </div>
                  <div className="col-md-12" style={{ marginTop: "15px" }}>
                    <div className="group-comb">
                      <div className="form-group">
                        <label>Points*</label>
                        <input
                          type="text"
                          // value={dealName}
                          onChange={(e) => {
                            if (e.target.value.length === 0) {
                              setPoints(0);
                            } else {
                              setPoints(e.target.value);
                              setPointError("");
                            }
                          }}
                          className="form-control"
                          style={{ paddingLeft: "5px" }}
                          // id="exampleFormControlInput1"
                        />
                      </div>
                      <p
                        style={{
                          color: "red",
                          fontSize: "11.5px",
                          paddingLeft: "5px",
                        }}
                      >
                        {pointsError}
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    style={{ margin: "5px 0 0 20px" }}
                    onClick={() => {
                      setMessage({
                        status: "specialize",
                        display: true,
                      });
                      check();
                    }}
                    className="btn  btn-cannaby"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        {message.status === "update" ? (
          <div style={{ textAlign: "center" }}>
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p className="popup-heading">
              Do you want to update loyalty points conditions ?
            </p>
            <button
              className="btn btn-cannaby"
              onClick={() => {
                updateLoyalty();
              }}
            >
              Yes
            </button>
            <button
              style={{ marginLeft: "20px", marginTop: "30px" }}
              type="button"
              className="btn popup-cancel-button"
              onClick={closeTooltip}
            >
              No
            </button>
          </div>
        ) : message.status === "close" ? (
          <div style={{ textAlign: "center" }}>
            <i
              // style={{
              //   color: "green",
              //   fontSize: "70px",
              // }}
              className="fa fa-check"
            ></i>
            <h2 className="popup-heading" style={{ marginTop: "20px" }}>
              Success
            </h2>
            <p>Conditions updated successfully</p>
            <button
              style={{ marginTop: "0" }}
              type="button"
              onClick={() => {
                closeTooltip();
                history.push("/loyalty-points");
              }}
              className="btn btn-primary"
            >
              Close
            </button>
          </div>
        ) : message.status === "specialize" ? (
          <div style={{ textAlign: "center" }}>
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p className="popup-heading">
              Do you want to update specialize points conditions ?
            </p>
            <button
              className="btn btn-cannaby"
              onClick={() => {
                updateSpecializeTime();
              }}
            >
              Yes
            </button>
            <button
              style={{ marginLeft: "20px", marginTop: "30px" }}
              type="button"
              className="btn popup-cancel-button"
              onClick={closeTooltip}
            >
              No
            </button>
          </div>
        ) : (
          ""
        )}
      </Popup>
    </div>
  );
}
