// eslint-disable-next-line react-hooks/exhaustive-deps
import { React, useState, useRef, useEffect } from "react";
import "./SideNav.css";
import { NavLink } from "react-router-dom";
import brandImage from "../assets/brandImage.png";
import { useHistory } from "react-router-dom";
import dashboard from "../assets/dashboard.svg";
import loyalty from "../assets/loyalty.svg";
import page from "../assets/pages.svg";
import promotion from "../assets/Promotion.svg";
import banner from "../assets/banner.svg";
import deals from "../assets/deals.svg";
import SettingsIcon from "@mui/icons-material/Settings";
// import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
function Sidenav() {
  // const [admin, setAdmin] = useState(false);
  const history = useHistory();
  const [catalogClick, setCatalogClick] = useState(true);
  const [promotionClick, setPromotionClick] = useState(true);
  // const [loyaltyClick, setLoyaltyClick] = useState(true);
  // const [supportClick, setSupportClick] = useState(true);
  const scrollRef = useRef();
  const [mainLogoRef, setMainLogoRef] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const adminData = JSON.parse(localStorage.getItem("Admin"));
      if (Array.isArray(adminData) && !!adminData?.length) {
        const mainLogoSource = adminData[0]?.media?.mainLogoFileSource ?? null;
        setMainLogoRef(mainLogoSource);
      }
    } catch (e) {}

    setLoading(false);
  }, [mainLogoRef]);

  // useEffect(() => {
  //   const isAdmin = JSON.parse(localStorage.getItem("Admin"));
  //   console.log(isAdmin, "admin");
  //   if (isAdmin && isAdmin[0].isSubOutlet) {
  //     setAdmin(true);
  //   } else {
  //     setAdmin(false);
  //   }
  // }, []);

  // const Routes = [
  //   // {
  //   //   name: "Dashboard",
  //   //   route: "/dashboard",
  //   //   icon: "fas fa-tachometer-alt",
  //   // },
  //   {
  //     name: "Outlets",
  //     route: "/outlets",
  //     icon: "fas fa-store-alt",
  //   },
  //   {
  //     name: "Inventory",
  //     route: "/inventory",
  //     icon: "fas fa-archive",
  //   },
  //   {
  //     name: "Catalog",
  //     route: "/catalog-outlet",
  //     icon: "fas fa-store-alt",
  //   },
  //   {
  //     name: "Orders",
  //     route: "/order",
  //     icon: "fas fa-archive",
  //   },
  //   {
  //     name: "Users",
  //     route: "/users",
  //     icon: "fas fa-user",
  //   },
  //   {
  //     name: "Banner",
  //     route: "/banner",
  //     icon: "fas fa-bookmark",
  //   },
  //   {
  //     name: "Deals",
  //     route: "/deals",
  //     icon: "fas fa-tag",
  //   },
  //   {
  //     name: "Discounts",
  //     route: "/discounts",
  //     icon: "fas fa-tag",
  //   },
  //   // {
  //   //   name: "Membership",
  //   //   route: "/membership",
  //   //   icon: "fas fa-tag",
  //   // },
  //   {
  //     name: "Add Missing Product",
  //     route: "/add-missing",
  //     icon: "fas fa-tag",
  //   },
  //   {
  //     name: "Page",
  //     route: "/page",
  //     icon: "fas fa-tag",
  //   },
  //   {
  //     name: "General Settings",
  //     route: "/setting",
  //     icon: "fas fa-tag",
  //   },

  //   // {
  //   //   name: "Chats",
  //   //   route: "/categories",
  //   //   icon: "fas fa-comment-alt",
  //   // },
  // ];

  // //single outlet admin routes
  // const RoutesOutlet = [
  //   // {
  //   //   name: "Catalog",
  //   //   route: "/catalog-outlet",
  //   //   icon: "fas fa-store-alt",
  //   // },
  //   {
  //     name: "Inventory",
  //     route: "/inventory-outlet",
  //     icon: "fas fa-archive",
  //   },
  //   {
  //     name: "Categories",
  //     route: "/categories",
  //     icon: "fas fa-shapes",
  //   },
  //   {
  //     name: "Manufacturers",
  //     route: "/brands",
  //     icon: "fas fa-tags",
  //   },
  //   {
  //     name: "Orders",
  //     route: "/order-outlet",
  //     icon: "fas fa-archive",
  //   },
  //   {
  //     name: "Users",
  //     route: "/users",
  //     icon: "fas fa-user",
  //   },
  //   {
  //     name: "Banner",
  //     route: "/banner",
  //     icon: "fas fa-bookmark",
  //   },
  //   {
  //     name: "Deals",
  //     route: "/deals-outlet",
  //     icon: "fas fa-tag",
  //   },
  //   {
  //     name: "Discounts",
  //     route: "/discounts-outlet",
  //     icon: "fas fa-tag",
  //   },
  //   // {
  //   //   name: "Membership",
  //   //   route: "/membership",
  //   //   icon: "fas fa-tag",
  //   // },
  //   {
  //     name: "License",
  //     route: "/license",
  //     icon: "fas fa-tag",
  //   },
  //   {
  //     name: "Applied License",
  //     route: "/applied-license",
  //     icon: "fas fa-tag",
  //   },
  //   {
  //     name: "Page",
  //     route: "/page",
  //     icon: "fas fa-tag",
  //   },
  //   {
  //     name: "General Settings",
  //     route: "/setting",
  //     icon: "fas fa-tag",
  //   },

  //   // {
  //   //   name: "Add Missing Product",
  //   //   route: "/add-missing",
  //   //   icon: "fas fa-tag"
  //   // },

  useEffect(() => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, [promotionClick, catalogClick]);

  const logout = () => {
    const tempRememberMeState = localStorage.getItem("remeberMeState");
    localStorage.clear();
    try {
      localStorage.setItem(
        "remeberMeState",
        tempRememberMeState ??
          JSON.stringify({ rememberMe: false, email: "", password: "" })
      );
    } catch (e) {}
    history.push("/");
  };
  return (
    <div
      className="col-lg-2 col-md-4 col-sm-4 side-nav "
      style={{ padding: " 0px 10px" }}
    >
      <div
        // className="card card-side-nave table-ac table-mod table-mod-data-ac custom-scrollbar"
        className="card custom-scrollbar"
        style={{
          height: "95vh",
          width: "15%",
          position: "fixed",
          padding: "0",
        }}
      >
        <div
          className="sidebar"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0em",
            paddingLeft: "0.2em",
          }}
        >
          <div style={{ textAlign: "center", height: "auto", padding: "1em" }}>
            {!loading && (
              <img
                alt="yo4"
                src={mainLogoRef ? mainLogoRef : brandImage}
                className="img-fluid "
                width="100"
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              overflow: "auto",
            }}
            className="custom-scrollbar"
          >
            <ul className="nav nav-pills flex-column cannaby-nav">
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/dashboard"
                >
                  {/* <i className="fa fa-tag"></i> */}
                  <i>
                    <img
                      alt="dashboard"
                      style={{ marginTop: "-3px" }}
                      src={dashboard}
                    />
                  </i>
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item drop-menu">
                <button
                  activeClassName="active "
                  className="nav-link btn "
                  data-toggle="collapse"
                  data-target="#demo"
                  to=""
                  onClick={() => setCatalogClick(!catalogClick)}
                >
                  <i className="fas fa-tag"></i>

                  <span>Catalog</span>
                  <i
                    style={{ marginLeft: "20px" }}
                    className={
                      catalogClick ? "fa fa-angle-right" : "fa fa-angle-up"
                    }
                  ></i>
                  <span></span>
                </button>
                <div
                  id="demo"
                  className="collapse"
                  style={{ marginBottom: "-10px" }}
                  ref={scrollRef}
                >
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      to="/inventory-outlet"
                    >
                      <i className="fa fa-archive"></i>
                      Products
                    </NavLink>
                  </li>
                  <li
                    className="nav-item"
                    style={{
                      display: "flex",
                      marginRight: "-30px",
                    }}
                  >
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      to="/categories"
                    >
                      <i className="fa fa-shapes"></i>
                      Categories
                    </NavLink>
                  </li>
                  <li
                    className="nav-item"
                    style={{
                      display: "flex",
                      marginRight: "-30px",
                    }}
                  >
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      to="/brands"
                      // style={{ margin: "0px" }}
                    >
                      <i className="fa fa-bookmark"></i>
                      Manufacturers
                    </NavLink>
                  </li>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/users"
                >
                  <i className="fa fa-user"></i>
                  Users
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/order-outlet"
                >
                  <i className="fa fa-truck"></i>
                  Orders
                </NavLink>
              </li>
              <li className="nav-item drop-menu">
                <button
                  activeClassName="active "
                  className="nav-link btn"
                  data-toggle="collapse"
                  data-target="#demo1"
                  to=""
                  onClick={() => setPromotionClick(!promotionClick)}
                >
                  <i>
                    <img alt="dashboard" src={promotion} />
                  </i>
                  <span>Promotions</span>
                  <i
                    style={{ marginLeft: "20px" }}
                    className={
                      promotionClick ? "fa fa-angle-right" : "fa fa-angle-up"
                    }
                  ></i>
                </button>
                <div id="demo1" className="collapse">
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      to="/banner"
                    >
                      <i>
                        <img alt="dashboard" src={banner} />
                      </i>
                      Banners
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      to="/deals"
                    >
                      <i>
                        <img alt="dashboard" src={deals} />
                      </i>
                      Deals
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      to="/discounts-outlet"
                    >
                      <i className="fa fa-percent"></i>
                      Coupons
                    </NavLink>
                  </li>{" "}
                  {/* <li className="nav-item">
                  <NavLink
                    activeClassName="active"
                    className="nav-link "
                    to="/membership"
                  >
                    <i className="fa fa-tag"></i>
                    Membership
                  </NavLink>
                </li> */}
                </div>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/License">
                  <i className="fa fa-tag"></i>
                  Licenses
                </NavLink>
              </li> */}
              {/* <li className="nav-item drop-menu">
                <button
                  activeClassName="active "
                  className="nav-link btn"
                  data-toggle="collapse"
                  data-target="#demo2"
                  to=""
                  onClick={() => setLoyaltyClick(!loyaltyClick)}>
                  <i className="fa fa-tag"></i>
                  <span>Loyalty Programs</span>
                  <i
                    style={{ marginLeft: "20px" }}
                    className={
                      loyaltyClick ? "fa fa-angle-right" : "fa fa-angle-up"
                    }></i>
                </button>
                <div id="demo2" className="collapse">
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      to="/loyalty-campaign">
                      <i className="fa fa-tag"></i>
                      Campaigns
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      to="/loyalty-product-point">
                      <i className="fa fa-tag"></i>
                      Product Points
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      to="/loyalty-customer-point">
                      <i className="fa fa-percent"></i>
                      Customer Points
                    </NavLink>
                  </li>{" "}
                </div>
              </li> */}
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/Page"
                >
                  <i>
                    <img alt="dashboard" src={page} />
                  </i>
                  Pages
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/loyalty-points"
                >
                  <i>
                    <img alt="dashboard" src={loyalty} />
                  </i>
                  Loyalty Points
                </NavLink>
              </li>
              {/* <li className="nav-item">
              <NavLink
                activeClassName="active"
                className="nav-link "
                to="/setting">
                <i className="fa fa-tag"></i>
                Settings
              </NavLink>
            </li> */}
              {/* <li className="nav-item drop-menu">
                <button
                  activeClassName="active "
                  className="nav-link btn"
                  data-toggle="collapse"
                  data-target="#demo3"
                  to=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => setSupportClick(!supportClick)}>
                  <ChatBubbleIcon fontSize="small" /> &nbsp;
                  <span>Support</span>
                  <i
                    style={{ marginLeft: "20px" }}
                    className={
                      supportClick ? "fa fa-angle-right" : "fa fa-angle-up"
                    }></i>
                </button>
                <div id="demo3" className="collapse">
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      to="/support-desk">
                      <i
                        className="fa-solid fa-headset"
                        style={{ fontStyle: "normal" }}></i>
                      Support Desk
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      to="/conversations">
                      <i
                        className="fa-solid fa-comments"
                        style={{ fontStyle: "normal" }}></i>
                      Conversations
                    </NavLink>
                  </li>
                </div>
              </li> */}
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/my-notifications"
                >
                  <i
                    class="fa-solid fa-bell"
                    style={{ fontStyle: "normal" }}
                  ></i>
                  Notifications
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link "
                  to="/general-settings"
                  style={{ alignItems: "center" }}
                >
                  <SettingsIcon fontSize="small" /> &nbsp;Settings
                </NavLink>
              </li>
              <li className="nav-item">
                <button
                  className="btn btn-danger bg-none text-danger nav-link"
                  onClick={() => logout()}
                >
                  <i className="fas fa-sign-out-alt"></i>logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidenav;
