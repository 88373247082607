/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import url from "../../config/axios";
import Popup from "reactjs-popup";
import "./loyalty.css";

export default function Index() {
  const history = useHistory();
  const admin = JSON.parse(localStorage.getItem("Admin"));
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    ref.current.close();
  };
  const [message, setMessage] = useState({
    display: false,
    status: "",
    text: "",
  });
  const [loyalty, setLoyalty] = useState();
  const [resetLoyalty, setResetLoyalty] = useState();
  const GetLoyaltyStandards = async () => {
    await url
      .get(`/loyalty?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          await setLoyalty(res.data.data);
          let temp = [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ];
          await setResetLoyalty(() => {
            return {
              ...loyalty,
              monday: temp,
              tuesday: temp,
              wednesday: temp,
              thursday: temp,
              friday: temp,
              saturday: temp,
              sunday: temp,
            };
          });
        } else if (res.data.data === undefined) {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };
  const updateLoyalty = async () => {
    console.log(loyalty, "function");
    await url
      .put(
        `/loyalty?loyaltyID=${loyalty.loyaltyID}&consumerId=${admin[0].consumerId}`,
        loyalty
      )
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          setMessage({
            status: "close",
            display: true,
          });
          openTooltip();
          // setTimeout(() => {
          //   closeTooltip();
          // }, 3000);
          // setTimeout(() => {

          // }, 6000);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleChange = (value, day, time) => {
    // console.log(value, day, time);
    if (value === "") {
      value = loyalty[day][time];
    }
    let temp = loyalty[day];
    temp[time] = value;

    setLoyalty((prev) => {
      return {
        ...prev,
        day: temp,
      };
    });
    console.log(loyalty, "updated");
  };

  const resetValue = async () => {
    let temp = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ];
    await setLoyalty((prev) => {
      return {
        ...prev,
        monday: temp,
        tuesday: temp,
        wednesday: temp,
        thursday: temp,
        friday: temp,
        saturday: temp,
        sunday: temp,
      };
    });
    console.log(resetLoyalty, "function");
    await url
      .put(
        `/loyalty?loyaltyID=${loyalty.loyaltyID}&consumerId=${admin[0].consumerId}`,
        resetLoyalty
      )
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          setMessage({
            status: "close",
            display: true,
          });
          openTooltip();
          setTimeout(() => {
            closeTooltip();
            window.location.reload();
          }, 3000);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    GetLoyaltyStandards();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro loyalty">
          <div className="row" style={{ padding: "15px 30px" }}>
            <div className="col-md-6"></div>
            <div className="col-md-6" style={{ textAlign: "right" }}>
              <button
                className="btn btn-primary"
                onClick={() => {
                  history.push("/loyalty-add-points");
                }}
              >
                <i className="fas fa-plus-circle"></i>
                Add Points
              </button>
            </div>
          </div>
          <table className="table table-striped-cust">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Monday</th>
                <th scope="col">Tuesday</th>
                <th scope="col">Wednesday</th>
                <th scope="col">Thursday</th>
                <th scope="col">Friday</th>
                <th scope="col">Saturday</th>
                <th scope="col">Sunday</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>12 AM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[0]}
                    onChange={(e) => handleChange(e.target.value, "monday", 0)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[0]}
                    onChange={(e) => handleChange(e.target.value, "tuesday", 0)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[0]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 0)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[0]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 0)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[0]}
                    onChange={(e) => handleChange(e.target.value, "friday", 0)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[0]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 0)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[0]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 0)}
                  />
                </td>
              </tr>
              <tr>
                <td>1 AM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[1]}
                    onChange={(e) => handleChange(e.target.value, "monday", 1)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[1]}
                    onChange={(e) => handleChange(e.target.value, "tuesday", 1)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[1]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 1)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[1]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 1)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[1]}
                    onChange={(e) => handleChange(e.target.value, "friday", 1)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[1]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 1)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[1]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 1)}
                  />
                </td>
              </tr>
              <tr>
                <td>2 AM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[2]}
                    onChange={(e) => handleChange(e.target.value, "monday", 2)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[2]}
                    onChange={(e) => handleChange(e.target.value, "tuesday", 2)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[2]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 2)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[2]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 2)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[2]}
                    onChange={(e) => handleChange(e.target.value, "friday", 2)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[2]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 2)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[2]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 2)}
                  />
                </td>
              </tr>
              <tr>
                <td>3 AM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[3]}
                    onChange={(e) => handleChange(e.target.value, "monday", 3)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[3]}
                    onChange={(e) => handleChange(e.target.value, "tuesday", 3)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[3]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 3)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[3]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 3)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[3]}
                    onChange={(e) => handleChange(e.target.value, "friday", 3)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[3]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 3)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[3]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 3)}
                  />
                </td>
              </tr>
              <tr>
                <td>4 AM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[4]}
                    onChange={(e) => handleChange(e.target.value, "monday", 4)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[4]}
                    onChange={(e) => handleChange(e.target.value, "tuesday", 4)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[4]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 4)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[4]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 4)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[4]}
                    onChange={(e) => handleChange(e.target.value, "friday", 4)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[4]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 4)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[4]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 4)}
                  />
                </td>
              </tr>
              <tr>
                <td>5 AM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[5]}
                    onChange={(e) => handleChange(e.target.value, "monday", 5)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[5]}
                    onChange={(e) => handleChange(e.target.value, "tuesday", 5)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[5]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 5)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[5]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 5)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[5]}
                    onChange={(e) => handleChange(e.target.value, "friday", 5)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[5]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 5)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[5]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 5)}
                  />
                </td>
              </tr>
              <tr>
                <td>6 AM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[6]}
                    onChange={(e) => handleChange(e.target.value, "monday", 6)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[6]}
                    onChange={(e) => handleChange(e.target.value, "tuesday", 6)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[6]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 6)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[6]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 6)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[6]}
                    onChange={(e) => handleChange(e.target.value, "friday", 6)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[6]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 6)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[6]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 6)}
                  />
                </td>
              </tr>
              <tr>
                <td>7 AM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[7]}
                    onChange={(e) => handleChange(e.target.value, "monday", 7)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[7]}
                    onChange={(e) => handleChange(e.target.value, "tuesday", 7)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[7]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 7)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[7]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 7)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[7]}
                    onChange={(e) => handleChange(e.target.value, "friday", 7)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[7]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 7)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[7]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 7)}
                  />
                </td>
              </tr>
              <tr>
                <td>8 AM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[8]}
                    onChange={(e) => handleChange(e.target.value, "monday", 8)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[8]}
                    onChange={(e) => handleChange(e.target.value, "tuesday", 8)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[8]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 8)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[8]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 8)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[8]}
                    onChange={(e) => handleChange(e.target.value, "friday", 8)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[8]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 8)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[8]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 8)}
                  />
                </td>
              </tr>
              <tr>
                <td>9 AM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[9]}
                    onChange={(e) => handleChange(e.target.value, "monday", 9)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[9]}
                    onChange={(e) => handleChange(e.target.value, "tuesday", 9)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[9]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 9)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[9]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 9)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[9]}
                    onChange={(e) => handleChange(e.target.value, "friday", 9)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[9]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 9)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[9]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 9)}
                  />
                </td>
              </tr>
              <tr>
                <td>10 AM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[10]}
                    onChange={(e) => handleChange(e.target.value, "monday", 10)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[10]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 10)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[10]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 10)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[10]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 10)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[10]}
                    onChange={(e) => handleChange(e.target.value, "friday", 10)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[10]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 10)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[10]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 10)}
                  />
                </td>
              </tr>
              <tr>
                <td>11 AM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[11]}
                    onChange={(e) => handleChange(e.target.value, "monday", 11)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[11]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 11)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[11]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 11)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[11]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 11)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[11]}
                    onChange={(e) => handleChange(e.target.value, "friday", 11)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[11]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 11)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[11]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 11)}
                  />
                </td>
              </tr>
              <tr>
                <td>12 PM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[12]}
                    onChange={(e) => handleChange(e.target.value, "monday", 12)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[12]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 12)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[12]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 12)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[12]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 12)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[12]}
                    onChange={(e) => handleChange(e.target.value, "friday", 12)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[12]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 12)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[12]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 12)}
                  />
                </td>
              </tr>
              <tr>
                <td>13 PM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[13]}
                    onChange={(e) => handleChange(e.target.value, "monday", 13)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[13]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 13)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[13]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 13)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[13]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 13)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[13]}
                    onChange={(e) => handleChange(e.target.value, "friday", 13)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[13]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 13)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[13]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 13)}
                  />
                </td>
              </tr>
              <tr>
                <td>14 PM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[14]}
                    onChange={(e) => handleChange(e.target.value, "monday", 14)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[14]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 14)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[14]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 14)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[14]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 14)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[14]}
                    onChange={(e) => handleChange(e.target.value, "friday", 14)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[14]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 14)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[14]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 14)}
                  />
                </td>
              </tr>
              <tr>
                <td>15 PM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[15]}
                    onChange={(e) => handleChange(e.target.value, "monday", 15)}
                  />
                </td>

                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[15]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 15)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[15]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 15)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[15]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 15)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[15]}
                    onChange={(e) => handleChange(e.target.value, "friday", 15)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[15]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 15)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[15]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 15)}
                  />
                </td>
              </tr>
              <tr>
                <td>16 PM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[16]}
                    onChange={(e) => handleChange(e.target.value, "monday", 16)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[16]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 16)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[16]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 16)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[16]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 16)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[16]}
                    onChange={(e) => handleChange(e.target.value, "friday", 16)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[16]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 16)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[16]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 16)}
                  />
                </td>
              </tr>
              <tr>
                <td>17 PM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[17]}
                    onChange={(e) => handleChange(e.target.value, "monday", 17)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[17]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 17)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[17]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 17)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[17]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 17)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[17]}
                    onChange={(e) => handleChange(e.target.value, "friday", 17)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[17]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 17)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[17]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 17)}
                  />
                </td>
              </tr>
              <tr>
                <td>18 PM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[18]}
                    onChange={(e) => handleChange(e.target.value, "monday", 18)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[18]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 18)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[18]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 18)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[18]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 18)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[18]}
                    onChange={(e) => handleChange(e.target.value, "friday", 18)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[18]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 18)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[18]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 18)}
                  />
                </td>
              </tr>
              <tr>
                <td>19 PM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[19]}
                    onChange={(e) => handleChange(e.target.value, "monday", 19)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[19]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 19)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[19]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 19)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[19]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 19)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[19]}
                    onChange={(e) => handleChange(e.target.value, "friday", 19)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[19]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 19)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[19]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 19)}
                  />
                </td>
              </tr>
              <tr>
                <td>20 PM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[20]}
                    onChange={(e) => handleChange(e.target.value, "monday", 20)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[20]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 20)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[20]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 20)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[20]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 20)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[20]}
                    onChange={(e) => handleChange(e.target.value, "friday", 20)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[20]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 20)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[20]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 20)}
                  />
                </td>
              </tr>
              <tr>
                <td>21 PM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[21]}
                    onChange={(e) => handleChange(e.target.value, "monday", 21)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[21]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 21)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[21]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 21)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[21]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 21)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[21]}
                    onChange={(e) => handleChange(e.target.value, "friday", 21)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[21]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 21)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[21]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 21)}
                  />
                </td>
              </tr>
              <tr>
                <td>22 PM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[22]}
                    onChange={(e) => handleChange(e.target.value, "monday", 22)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[22]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 22)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[22]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 22)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[22]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 22)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[22]}
                    onChange={(e) => handleChange(e.target.value, "friday", 22)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[22]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 22)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[22]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 22)}
                  />
                </td>
              </tr>
              <tr>
                <td>23 PM</td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.monday[23]}
                    onChange={(e) => handleChange(e.target.value, "monday", 23)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.tuesday[23]}
                    onChange={(e) =>
                      handleChange(e.target.value, "tuesday", 23)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.wednesday[23]}
                    onChange={(e) =>
                      handleChange(e.target.value, "wednesday", 23)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.thursday[23]}
                    onChange={(e) =>
                      handleChange(e.target.value, "thursday", 23)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.friday[23]}
                    onChange={(e) => handleChange(e.target.value, "friday", 23)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.saturday[23]}
                    onChange={(e) =>
                      handleChange(e.target.value, "saturday", 23)
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.abs(e.target.value);
                      if (e.target.value > 100) {
                        e.target.value = 100;
                      }
                    }}
                    placeholder={loyalty?.sunday[23]}
                    onChange={(e) => handleChange(e.target.value, "sunday", 23)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ textAlign: "center", margin: "30px" }}>
          <button
            className="btn btn-primary"
            onClick={() => {
              setMessage({
                status: "update",
                display: true,
              });
              openTooltip();
            }}
          >
            Update Table
          </button>
          <button
            style={{ marginLeft: "20px" }}
            className="btn btn-primary"
            onClick={() => {
              setMessage({
                status: "reset",
                display: true,
              });
              openTooltip();
            }}
          >
            Reset Table
          </button>
        </div>
      </div>
      <Popup ref={ref} position="center">
        {message.status === "update" ? (
          <div style={{ textAlign: "center" }}>
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p className="popup-heading">
              Do you want to update loyalty points conditions ?
            </p>
            <button
              className="btn btn-cannaby"
              onClick={() => {
                updateLoyalty();
              }}
            >
              Yes
            </button>
            <button
              style={{ marginLeft: "20px", marginTop: "30px" }}
              type="button"
              className="btn popup-cancel-button"
              onClick={closeTooltip}
            >
              No
            </button>
          </div>
        ) : message.status === "reset" ? (
          <div style={{ textAlign: "center" }}>
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p className="popup-heading">
              Do you want to reset loyalty points conditions ?
            </p>
            <button
              className="btn btn-cannaby"
              onClick={async () => {
                await resetValue();
              }}
            >
              Yes
            </button>
            <button
              style={{ marginLeft: "20px", marginTop: "30px" }}
              type="button"
              className="btn popup-cancel-button"
              onClick={closeTooltip}
            >
              No
            </button>
          </div>
        ) : message.status === "close" ? (
          <div style={{ textAlign: "center" }}>
            <i
              // style={{
              //   color: "green",
              //   fontSize: "70px",
              // }}
              className="fa fa-check"
            ></i>
            <h2 className="popup-heading" style={{ marginTop: "20px" }}>
              Success
            </h2>
            <p>Table updated successfully</p>
            <button
              style={{ marginTop: "10px" }}
              type="button"
              onClick={() => {
                closeTooltip();
                history.push("/loyalty-points");
              }}
              className="btn btn-primary"
            >
              Close
            </button>
          </div>
        ) : (
          ""
        )}
      </Popup>
    </div>
  );
}
