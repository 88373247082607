import axios from "axios";

const customInstance = axios.create({
  baseURL: "https://canopy.bleaum.dev/",
  // baseURL: "http://localhost:8087/",
  headers: { Accept: "application/json" },
  withCredentials: true,
});

export default customInstance;
