import { useState, useRef, useEffect, Fragment } from "react";
import url from "../../../config/axios";
import CircularProgress from "@mui/material/CircularProgress";
import { fileUpload } from "../../../helpers/firebaseFileUpload";
const initialFormState = {
  mainLogoFile: null,
  mainLogoFileName: null,
  mainLogoFileSource: null,
  footerLogoFile: null,
  footerLogoFileName: null,
  footerLogoFileSource: null,
  faviconLogoFile: null,
  faviconLogoFileName: null,
  faviconLogoFileSource: null,
};
function MediasPage({ invokeLogout }) {
  const [formData, setFormData] = useState({
    ...initialFormState,
  });

  /**
   *
   * @param {event} event Actual HTML file event
   * @param {string} target if target = "tom" => set"tom"FileName(filename) and set"tom"File(buffer) and set"tom"FileSource(sourceurl)
   */
  const imageSelector = (event, target) => {
    const allowedExtensions = ["png", "jpg", "jpeg", "gif", "webp"];
    if (event.target.files[0]) {
      let file = event.target.files[0];
      // console.log("***File is:", file);
      const splitted = (event.target.files[0]?.name + "").split(".");
      const extension = splitted[splitted.length - 1] ?? null;
      if (allowedExtensions.includes(extension)) {
        setFormData({
          ...formData,
          [target + "FileName"]: file.name,
          [target + "File"]: file,
          [target + "FileSource"]: URL.createObjectURL(file),
        });
      }
    }
  };
  const [inProgress, setInProgress] = useState(true);
  const [previousData, setPreviousData] = useState({});
  const mainLogoRef = useRef(null);
  const footerLogoRef = useRef(null);
  const faviconLogoRef = useRef(null);

  const updateMedias = async () => {
    setInProgress(true);
    let currentMainLogoFileSource = previousData.mainLogoFileSource;
    let currentFooterLogoFileSource = previousData.footerLogoFileSource;
    let currentFaviconLogoFileSource = previousData.faviconLogoFileSource;

    if (formData.footerLogoFile) {
      let url = await fileUpload(formData.footerLogoFile);
      if (!!url) {
        currentFooterLogoFileSource = url;
      }
    }
    if (formData.faviconLogoFile) {
      let url = await fileUpload(formData.faviconLogoFile);
      if (!!url) {
        currentFaviconLogoFileSource = url;
      }
    }
    if (formData.mainLogoFile) {
      let url = await fileUpload(formData.mainLogoFile);
      if (!!url) {
        currentMainLogoFileSource = url;
      }
    }
    const body = {
      faviconLogoFileSource: currentFaviconLogoFileSource,
      mainLogoFileSource: currentMainLogoFileSource,
      footerLogoFileSource: currentFooterLogoFileSource,
    };
    //sanity
    Object.keys(body).forEach((key) => {
      if (!body[key]) {
        delete body[key];
      }
    });
    await url
      .patch(`/v1/general-settings/media`, {
        ...body,
      })
      .then((res) => {
        let response = res.data?.data ?? {};
        if (res?.data?.Message === "Success") {
          updateLocalStorage(response);
        }
        setFormData({
          ...initialFormState,
          faviconLogoFileSource: response?.faviconLogoFileSource ?? null,
          mainLogoFileSource: response?.mainLogoFileSource ?? null,
          footerLogoFileSource: response?.footerLogoFileSource ?? null,
        });
        setPreviousData({
          faviconLogoFileSource: response?.faviconLogoFileSource ?? "",
          mainLogoFileSource: response?.mainLogoFileSource ?? "",
          footerLogoFileSource: response?.footerLogoFileSource ?? "",
        });
        setInProgress(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          invokeLogout();
        }
        setInProgress(false);
      });
  };

  /**
   *
   * @param {object} data media object
   */
  const updateLocalStorage = async (data) => {
    try {
      console.log("Here...");
      const prevData = await JSON.parse(localStorage.getItem("Admin"));
      if (Array.isArray(prevData)) {
        prevData[0] = { ...prevData[0], media: { ...data } };
        console.log("Prev data is: ", prevData);
        await localStorage.setItem("Admin", JSON.stringify(prevData));
        window.location.reload();
      }
    } catch (e) {}
  };
  useEffect(
    () => {
      const adminID = JSON.parse(localStorage.getItem("Admin"));
      const outletChainID = adminID[0]?.outletChainID ?? null;
      url
        .get(`/v1/general-settings/media?outletChainID=${outletChainID}`)
        .then((res) => {
          let response = res.data?.data ?? {};
          setFormData({
            ...formData,
            faviconLogoFileSource: response?.faviconLogoFileSource ?? null,
            mainLogoFileSource: response?.mainLogoFileSource ?? null,
            footerLogoFileSource: response?.footerLogoFileSource ?? null,
          });
          setPreviousData({
            faviconLogoFileSource: response?.faviconLogoFileSource ?? "",
            mainLogoFileSource: response?.mainLogoFileSource ?? "",
            footerLogoFileSource: response?.footerLogoFileSource ?? "",
          });
          setInProgress(false);
        })
        .catch((e) => {
          setInProgress(false);
        });
    },
    //eslint-disable-next-line
    []
  );
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2em" }}>
      {inProgress ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Fragment>
          {/* *********Main Logo */}
          <div className="row">
            <div className="col-md-6">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1em",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Main Logo</span>
                <div className="row">
                  <div
                    style={{
                      backgroundColor: "lightgray",
                      padding: "0.5em 0.8em",
                      width: "auto",
                      cursor: "pointer",
                    }}
                    onClick={() => mainLogoRef.current.click()}
                  >
                    Choose File
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyItems: "center",
                      padding: "0.5em 0.8em",
                      backgroundColor: "#ebf0ec",
                      flexGrow: 1,
                    }}
                  >
                    {!!formData.mainLogoFile &&
                    !!formData.mainLogoFileName &&
                    !!formData.mainLogoFileSource
                      ? (formData.mainLogoFileName + "").length > 45
                        ? (formData.mainLogoFileName + "").substring(0, 45) +
                          "..."
                        : formData.mainLogoFileName
                      : "No file Chosen"}
                  </div>
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={(e) => imageSelector(e, "mainLogo")}
                    accept="image/*"
                    lang="en"
                    ref={mainLogoRef}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {formData.mainLogoFileSource && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "lightgray",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={formData.mainLogoFileSource}
                    alt={formData.mainLogoFileName}
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                    }}
                    lassName="img-fluid "
                    width="200"
                    height="200"
                  />
                </div>
              )}
            </div>
          </div>

          {/* *********Footer Logo */}
          <div className="row">
            <div className="col-md-6">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1em",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Footer Logo</span>
                <div className="row">
                  <div
                    style={{
                      backgroundColor: "lightgray",
                      padding: "0.5em 0.8em",
                      width: "auto",
                      cursor: "pointer",
                    }}
                    onClick={() => footerLogoRef.current.click()}
                  >
                    Choose File
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyItems: "center",
                      padding: "0.5em 0.8em",
                      backgroundColor: "#ebf0ec",
                      flexGrow: 1,
                    }}
                  >
                    {!!formData.footerLogoFile &&
                    !!formData.footerLogoFileName &&
                    !!formData.footerLogoFileSource
                      ? (formData.footerLogoFileName + "").length > 45
                        ? (formData.footerLogoFileName + "").substring(0, 45) +
                          "..."
                        : formData.footerLogoFileName
                      : "No file Chosen"}
                  </div>
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={(e) => imageSelector(e, "footerLogo")}
                    accept="image/*"
                    lang="en"
                    ref={footerLogoRef}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {formData.footerLogoFileSource && (
                <div
                  style={{
                    display: "flex",
                    overflow: "hidden",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "lightgray",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    src={formData.footerLogoFileSource}
                    alt={formData.footerLogoFileName}
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                    }}
                    lassName="img-fluid "
                    width="200"
                    height="200"
                  />
                </div>
              )}
            </div>
          </div>

          {/* ******Favicon */}
          <div className="row">
            <div className="col-md-6">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1em",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Favicon</span>
                <div className="row">
                  <div
                    style={{
                      backgroundColor: "lightgray",
                      padding: "0.5em 0.8em",
                      width: "auto",
                      cursor: "pointer",
                    }}
                    onClick={() => faviconLogoRef.current.click()}
                  >
                    Choose File
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyItems: "center",
                      padding: "0.5em 0.8em",
                      backgroundColor: "#ebf0ec",
                      flexGrow: 1,
                    }}
                  >
                    {!!formData.faviconLogoFile &&
                    !!formData.faviconLogoFileName &&
                    !!formData.faviconLogoFileSource
                      ? (formData.faviconLogoFileName + "").length > 45
                        ? (formData.faviconLogoFileName + "").substring(0, 45) +
                          "..."
                        : formData.faviconLogoFileName
                      : "No file Chosen"}
                  </div>
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={(e) => imageSelector(e, "faviconLogo")}
                    accept="image/*"
                    lang="en"
                    ref={faviconLogoRef}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {formData.faviconLogoFileSource && (
                <div
                  style={{
                    display: "flex",
                    overflow: "hidden",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "lightgray",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    src={formData.faviconLogoFileSource}
                    alt={formData.faviconLogoFileName}
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                    }}
                    height="200"
                    width="200"
                  />
                </div>
              )}
            </div>
          </div>

          <div
            className="row"
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "2em",
              marginTop: "3em",
            }}
          >
            <button
              onClick={updateMedias}
              style={{
                padding: "0.8em 3em",
                borderRadius: "5px",
                fontWeight: "bold",
                color: "white",
                backgroundColor: "#294d94",
                border: "none",
                cursor: "pointer",
              }}
            >
              Update Settings
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default MediasPage;
