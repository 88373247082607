const CategoryTable = ({ categories, editCategory }) => {
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];

  let num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    console.log(value, "VALUES");
    return value;
  }

  return (
    <div className="col-md-12">
      <table className="table table-striped-cust">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>

            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col">Products</th>
            <th scope="col">Parent Category</th>
            <th scope="col">Status</th>
            <th></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, i) => {
            return (
              <tr key={i}>
                <td colSpan="6">
                  {category.image ? (
                    <div>
                      <img
                        alt="yo1"
                        src={category.image}
                        className="img-fluid "
                        width="40"
                      />
                      {category.name}
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <div
                        className="image-thumbnail-div"
                        style={{
                          backgroundColor: assignColor(),
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <span>{category?.name?.substring(0, 1)}</span>
                      </div>
                      <span style={{ paddingTop: "10px" }}>
                        {category.name}
                      </span>
                    </div>
                  )}
                </td>
                <td>
                  {category.countInventory.length
                    ? category.countInventory[0].count
                    : 0}
                </td>
                <td>{category.parentCategory || "_"}</td>
                {/* <td>{moment(category.updatedAt).format(" MMM. Do YYYY, h:mm A")}</td> */}
                <td className={category.status ? "color-blue" : "color-red"}>
                  {category.status ? (
                    <span>Enabled</span>
                  ) : (
                    <span>Disabled</span>
                  )}
                </td>
                <td>
                  <i
                    style={{ cursor: "pointer" }}
                    onClick={() => editCategory(category)}
                    className="fas fa-pen"
                  ></i>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryTable;
